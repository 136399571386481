@import "../styles/login";

create-account {

  gs-page-layout {
    gs-form {
      .gs-form-header {
        border-bottom: none;
        padding: 0;
      }

      gs-form-section {
        border-bottom: none;
        padding: $xx-large-pad 0;
      }
    }
  }
}
