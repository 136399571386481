@import "../styles/login";

cookie-policy {
  .cookie-tray {
    justify-content: center;
  }

  @include large-phone {
    .cookie-tray {
      gs-list-item {
        .groups {
          width: 100%;

          .cookie-btn-container {
            width: 100%;

            .cookie-btn {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}
