@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');

body {
  color: #232323;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
